import React, { useEffect, useState } from 'react'
import { loadWistiaScript } from '@helpers/load-wistia-script'
import { initWistiaVideo } from '@helpers/init-wistia-video'
import renderBloks from '@renderBloks'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    cursor: 'pointer',
    textDecoration: ({ contentToHyperlink }) => contentToHyperlink && 'none',
  },
}))

const WistiaLink = (props) => {
  const shouldAutoLoadVideo = props.shouldAutoLoadVideo
  const classes = useStyles(props)
  const [initVideo, setInitVideo] = useState(null)
  const text = props.blok.text || 'Watch video'
  const content = props.contentToHyperlink || text

  useEffect(() => {
    !!initVideo && initVideo(props.blok.wistiaId, props.blok.onDismiss)
  }, [initVideo, props.blok.wistiaId, props.blok.onDismiss])

  const handleClick = (e) => {
    e.preventDefault()

    loadWistiaScript()
    setInitVideo((onDismiss) => {
      initWistiaVideo(props.blok.wistiaId, (video) => {
        video.play()

        if (onDismiss) {
          video.bind('popoverhide', onDismiss)
        }
      })
    })
  }

  const getWindowPath = () => {
    if (typeof window !== 'undefined') {
      return window.location.pathname
    }
    return null
  }

  const defaultLink = (content, wistiaId, classes) => {
    return (
      <a
        href={'#wistia'}
        className={classes.link}
        onClick={handleClick}
        data-analytics-handler="video-click"
        data-link-category={getWindowPath()}
        data-video-id={wistiaId}
      >
        {content}
      </a>
    )
  }

  return (
    <span>
      {!props.blok.button.length
        ? defaultLink(content, props.blok.wistiaId, classes)
        : renderBloks(props.blok.button, {
            isWistiaLink: true,
            onClick: handleClick,
            text: text,
          })}
      <span
        id={props.blok.wistiaId}
        style={{ height: 0, width: 0 }}
        className={`header-wistia-origin wistia_embed wistia_async_${
          props.blok.wistiaId
        } popover=true ${
          !!shouldAutoLoadVideo && 'popoverShowOnLoad=true autoPlay=false'
        } popoverAnimateThumbnail=true`}
      />
    </span>
  )
}

export default WistiaLink
